
import React from 'react';
import Hero from 'blocks/Hero';
import Image from 'blocks/Image';
import Text from 'blocks/Text';
import Columns from 'blocks/Columns';
import FactsAndFigures from 'blocks/FactsAndFigures';
import Quote from 'blocks/Quote';
import Grid from 'blocks/Grid';
import Layout from 'blocks/Layout';
import Default from 'blocks/Default';

const availableBlocks = {
  'Hero': Hero,
'Image': Image,
'Text': Text,
'Columns': Columns,
'FactsAndFigures': FactsAndFigures,
'Quote': Quote,
'Grid': Grid,
'Layout': Layout,
};

const Blocks = ({block, rowIndex}) => {
    const blockName = block.__typename.replace(/.*_([a-z]*)$/gi, '$1');

    return availableBlocks[blockName]
        ?  React.createElement(availableBlocks[blockName], {Blocks, rowIndex, ...block})
        : <Default blockType={blockName}/>;
};

export default Blocks;
